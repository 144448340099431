body{
  background: url("./images/background.jpg");
  font-family: 'Chau Philomene One', sans-serif;
}
.app-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #FFF1D9;
}

.form-container {
  margin: 0 auto;
  max-width: 75%;
}

input[type="text"],
select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  width: 100%;
  background-color: #E65826;
  color: white;
  padding: 14px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Chau Philomene One', sans-serif;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.nft-image {
  max-width: 100%;
  height: auto;
  border: 1px solid #ccc;
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .app-container {
    padding: 10px;
  }
}

.footer {
  border-top: 1px solid rgba(149, 91, 70, 0.5);
  padding: 20px 0;
  margin-top: 20px;
  text-align: center;
}

.footer-text {
  font-size: 14px;
}

.footer span{
  cursor:pointer;
}

select, option {
  -webkit-appearance: none;
}

h1, h2, h3 {
  text-transform: uppercase;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  color: #955b46;
}

.header-logo{
  max-width: 50%;
}